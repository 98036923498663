import React from 'react';
import Typography from '@mui/material/Typography';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { shortenWithThreeDotsInTheMiddle } from 'utils/shorten-with-three-dots-in-the-middle';

const DEFAULT_NUMBER_OF_DISPLAYED_CHARACTERS = 6;

type Props = {
  id: string;
  numberOfDisplayedCharacters?: number;
};

export const GuidCell: React.FC<Props> = ({
  id,
  numberOfDisplayedCharacters = DEFAULT_NUMBER_OF_DISPLAYED_CHARACTERS,
}) => {
  return (
    <CommonTooltip singleLine title={id} placement="top">
      <Typography variant="bodyDefaultBook">
        {shortenWithThreeDotsInTheMiddle(id, numberOfDisplayedCharacters)}
      </Typography>
    </CommonTooltip>
  );
};
